import React, { useEffect, useState } from "react"
import { Link } from "react-router-dom"
import s from "../assets/scss/blog.module.scss"
import AnimatedButton from "../components/AnimatedButton"

import axios from "axios"
import IconArrowXs from "../components/Icon/IconArrowXs"

import cx from "classnames"
import { useRef } from "react"

const BlogCard = ({
  blog = {
    title: "",
    image: "",
    url: "",
  },
}) => {
  function truncateString(str, num) {
    if (str.length <= num) {
      return str
    }
    return str.slice(0, num) + "..."
  }

  return (
    <Link to={blog.url} className={s.blogCard}>
      <div className={s.imgWrapper}>
        <img className={s.img} src={blog.image} alt="Blog Visual" />
      </div>
      <div className={s.text}>
        <h3 className={s.blogTitle}>{truncateString(blog.title, 60)}</h3>
        <div className={s.btnWrapper}>
          <AnimatedButton sm={true} text="Detaylı İncele"></AnimatedButton>
        </div>
      </div>
    </Link>
  )
}

export default function Blog() {
  // const blogs = [
  //   {
  //     image:
  //       "https://images.unsplash.com/photo-1579548122080-c35fd6820ecb?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=2070&q=80",
  //     title: "lol",
  //     url: "",
  //   },
  //   {
  //     image:
  //       "https://images.unsplash.com/photo-1579548122080-c35fd6820ecb?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=2070&q=80",
  //     title: "lol1",
  //     url: "",
  //   },
  //   {
  //     image:
  //       "https://images.unsplash.com/photo-1579548122080-c35fd6820ecb?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=2070&q=80",
  //     title: "lol2",
  //     url: "",
  //   },
  //   {
  //     image:
  //       "https://images.unsplash.com/photo-1579548122080-c35fd6820ecb?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=2070&q=80",
  //     title: "lol3",
  //     url: "",
  //   },
  // ];

  const keywordInputRef = useRef(null)
  const searchFormRef = useRef(null)

  const [items, setItems] = useState([])
  const [keyword, setKeyword] = useState(null)

  useEffect(() => {
    axios
      .get("https://ateon.justdesignfx.com/jd-admin/services/blog.php")
      .then((res) => {
        const data = res.data
        setItems([...data])
        console.log(res.data)
      })
      .catch((err) => {
        console.log(err)
      })
  }, [])

  useEffect(() => {
    const handleSubmit = () => {
      axios
        .get(`https://ateon.justdesignfx.com/jd-admin/services/blog.php?keyword=${keyword}`)
        .then((res) => {
          const data = res.data
          setItems([...data])
          console.log(res.data)
        })
        .catch((err) => {
          console.log(err)
        })
    }

    if (keyword !== null) {
      handleSubmit()
    }
  }, [keyword])

  return (
    <section className={s.blog}>
      <h1 className={s.title}>Blog İçerikleri</h1>

      <div className={s.content}>
        <form className={s.search} ref={searchFormRef}>
          <div className={s.inputWrapper}>
            <input
              ref={keywordInputRef}
              id={s.keyword}
              className={s.input}
              type="text"
              onChange={(e) => {
                setKeyword(e.target.value)
              }}
            />
            {!keyword && (
              <label className={s.label} htmlFor={s.keyword}>
                Bloglarda Arayın
              </label>
            )}
            <div className={s.iconWrapper}>
              <IconArrowXs />
            </div>
          </div>

          <div
            className={cx(s.cleanForm, { [s.disabled]: !keyword })}
            onClick={() => {
              setKeyword("")
              searchFormRef.current.reset()
            }}
          >
            <p className={s.text}>Temizle</p>
          </div>
        </form>
        <div className={s.blogs}>
          {items.length !== 0 ? (
            items.map((item, i) => {
              return <BlogCard key={i} blog={item}></BlogCard>
            })
          ) : (
            <div className={s.notFound}>
              <h1 className={s.text}>Blog Bulunamadı</h1>
            </div>
          )}
        </div>
      </div>
    </section>
  )
}
