import React, { useEffect, useState } from "react"
import s from "../assets/scss/blogDetail.module.scss"

import axios from "axios"
import { Link, useParams } from "react-router-dom"
import IconArrowXs from "../components/Icon/IconArrowXs"

import cx from "classnames"

export default function BlogDetail() {
  const params = useParams()
  // TODO TITLE
  const [data, setData] = useState({
    id: "",
    content: <p>lol</p>,
    title: "",
    last: "",
    url: "",
    nextBlog: "",
    buttons: [],
  })

  useEffect(() => {
    axios
      .get(`https://ateon.justdesignfx.com/jd-admin/services/blog.php?url=${params.blogId}`)
      .then((res) => {
        const data = res.data
        setData(data)
        console.log(data)
      })
      .catch((err) => {
        console.log(err)
      })
  }, [params])

  return (
    <div className={s.blogDetail}>
      {data && (
        <>
          <div className={cx(s.navBtn, s.prev)} data-sticky-item>
            <Link className={s.link} to="/blog">
              <div className={s.iconWrapper}>
                <IconArrowXs />
              </div>
              <span className={s.text}>Geri Dön</span>
            </Link>
          </div>
          <h1 className={s.title}>{data.title}</h1>
          <div className={s.tags}>
            {data.buttons &&
              data.buttons.length !== 0 &&
              data.buttons.map((item) => {
                return (
                  <Link to={item.buttonUrl} className={s.link}>
                    {item.buttonName}
                  </Link>
                )
              })}
          </div>
          <div className={s.imgWrapper}>
            <img className={s.img} src={data.image} alt="" />
          </div>
          <div dangerouslySetInnerHTML={{ __html: data.content }} className={s.text}></div>
          <div className={cx(s.navBtn, s.next)}>
            <Link className={s.link} to={`/blog/${data.nextBlog}`}>
              <span className={s.text}>Sıradaki Blog Yazısı</span>
              <div className={s.iconWrapper}>
                <IconArrowXs />
              </div>
            </Link>
          </div>
        </>
      )}
    </div>
  )
}
