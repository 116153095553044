import React, { useState } from "react"
import s from "../assets/scss/contactForm.module.scss"

import TextareaAutosize from "react-textarea-autosize"
import axios from "axios"
import cx from "classnames"

import AnimatedButton from "./AnimatedButton"
import IconInputArrow from "./Icon/IconInputArrow"
import Dropdown from "./Dropdown"
import { useRef } from "react"
import useWindowSize from "../hooks/useWindowSize"
import { breakpointTypes } from "../utils/types"
import { useCallback } from "react"

import kvkk from "../assets/pdf/websitesi-aydinlatma-metni.pdf"

export default function ContactForm({ contactPage = false }) {
  const nameInputRef = useRef(null)
  const subjectSelectRef = useRef(null)
  const mailInputRef = useRef(null)
  const phoneInputRef = useRef(null)
  const messageInputRef = useRef(null)
  const formRef = useRef(null)

  const [name, setName] = useState(null)
  const [subject, setSubject] = useState(null)
  const [mail, setMail] = useState(null)
  const [phone, setPhone] = useState(null)
  const [message, setMessage] = useState(null)
  const [mailConf, setMailConf] = useState(false)

  const windowSize = useWindowSize()

  const formSubjects = [
    { name: "Siber Güvenlik Çözümleri" },
    { name: "Ağ Çözümleri" },
    { name: "Donanım Çözümleri" },
    { name: "Sanallaştırma Çözümleri" },
    { name: "Kurumsal Dosya Yönetimi" },
    { name: "İyileştirme Hizmetleri" },
    { name: "Kurulum Hizmetleri" },
    { name: "Danışmanlık Hizmetleri" },
    { name: "Teknik Destek ve Bakım Hizmetleri" },
    { name: "Veritas" },
    { name: "Diğer" },
  ]

  const handleSubmit = () => {
    const formData = new FormData()

    formData.append("name", name)
    formData.append("subject", subject)
    formData.append("email", mail)
    formData.append("phone", phone)
    formData.append("message", message)

    const config = { headers: { "Content-Type": "multipart/form-data" } }

    // console.log(name, subject, mail, phone, message);

    if (name !== null && subject !== null && mail !== null && (phone !== null) & (message !== null)) {
      axios({
        method: "post",
        url: "https://ateon.justdesignfx.com/jd-admin/services/contact.php",
        data: formData,
        config,
      })
        .then((res) => {
          console.log(res.data)
          if (res.data.Message === "Mesaj Alındı Teşekkürler") {
            resetForm()
            alert("Formunuz başarıyla iletilmiştir!")
          }
        })
        .catch((err) => {
          console.log(err)
        })
    }
  }

  const resetForm = () => {
    setMail(null)
    setMailConf(null)
    setMessage(null)
    setName(null)
    setMessage(null)
    setPhone(null)
    subjectSelectRef.current.resetDropdown()

    formRef.current.reset()
  }

  const handleConf = () => {
    setMailConf((prev) => !prev)
  }

  const handleFocus = (e) => {
    if (e.type === "focus" && e) {
      e.target.labels[0].style.opacity = "0.1"
    } else if (e.type === "blur" && e) {
      e.target.labels[0].style.opacity = "1"
    }
  }

  const handleSubject = useCallback((val) => {
    if (subjectSelectRef.current) subjectSelectRef.current.closeDropdown()
    setSubject(val)
  }, [])

  return (
    <form className={cx(s.form, { [s.contactPage]: contactPage })} ref={formRef}>
      <div className={s.row}>
        <h6 className={s.text}>Merhaba, adım</h6>{" "}
        <div className={s.inputWrapper}>
          {!name && (
            <label className={s.label} htmlFor="name">
              Adınız soyadınız*
            </label>
          )}
          <input
            name="name"
            id="name"
            className={s.input}
            type="text"
            required
            onChange={(e) => {
              setName(e.currentTarget.value)
            }}
            onFocus={handleFocus}
            onBlur={handleFocus}
            ref={nameInputRef}
          />
        </div>
      </div>
      <div className={s.row}>
        <div className={cx(s.inputWrapper, s.subject)}>
          <Dropdown
            options={formSubjects}
            handleF={handleSubject}
            defaultOption="Konu seçiniz"
            ref={subjectSelectRef}
            icon={<IconInputArrow fill="#00509d"></IconInputArrow>}
            contactPage={contactPage}
          />

          {/* {!subject && (
            <label className={cx(s.label, s.labelSubject)} htmlFor="subject">
              Konu Seçiniz*
              <div className={s.arrow}>
                <IconInputArrow fill={"#00509d"}></IconInputArrow>
              </div>
            </label>
          )} */}
          {/* <div
            id="subject"
            className={cx(s.input, s.select)}
            name="subject"
            required
            onChange={(e) => {
              setSubject(e.currentTarget.value);
            }}
          >
            <optgroup className={s.optGroup}>
              <option value="" disabled selected hidden></option>
              {formSubject.map((subject, i) => {
                return (
                  <option className={s.opt} value={subject.ui} key={i}>
                    {subject.ui}
                  </option>
                );
              })}
            </optgroup>
          </div> */}
        </div>{" "}
        <h6 className={s.text}>hakkında bilgi almak istiyorum.</h6>
      </div>
      <div className={s.row}>
        <h6 className={s.text}>Bana</h6>
        <div className={s.inputWrapper}>
          {!mail && (
            <label className={s.label} htmlFor="mail">
              Eposta adresiniz*
            </label>
          )}
          <input
            name="mail"
            id="mail"
            className={s.input}
            type="email"
            required
            onChange={(e) => {
              setMail(e.currentTarget.value)
            }}
            onFocus={handleFocus}
            onBlur={handleFocus}
            ref={mailInputRef}
          />
        </div>
        <h6 className={s.text}>veya</h6>
        <div className={s.inputWrapper}>
          {!phone && (
            <label className={s.label} htmlFor="phone">
              Telefon numaranız*
            </label>
          )}
          <input
            name="phone"
            id="phone"
            className={s.input}
            type="text"
            onKeyPress={(event) => {
              if (!/[0-9]/.test(event.key)) {
                event.preventDefault()
              }
            }}
            required
            onChange={(e) => {
              setPhone(e.currentTarget.value)
            }}
            onFocus={handleFocus}
            onBlur={handleFocus}
            ref={phoneInputRef}
          />
        </div>
        <h6 className={s.text}>
          üzerinden {windowSize.width <= breakpointTypes.mobile && <span>ulaşabilirsiniz.</span>}
        </h6>
      </div>
      {windowSize.width >= breakpointTypes.mobile && (
        <div className={s.row}>
          <h6 className={s.text}>ulaşabilirsiniz.</h6>
        </div>
      )}

      <div className={s.row}>
        <div className={cx(s.inputWrapper, s.tArea)}>
          {!message && (
            <label
              className={s.label}
              htmlFor="message"
              style={{
                justifyContent: windowSize.width > breakpointTypes.mobile ? "flex-start" : "center",
                textAlign: windowSize.width > breakpointTypes.mobile ? "left" : "center",
              }}
            >
              Ek mesaj alanı
            </label>
          )}
          <TextareaAutosize
            id="message"
            name="message"
            type="text"
            required
            className={s.resizableTextarea}
            //className={s.input}
            onChange={(e) => {
              setMessage(e.currentTarget.value)
            }}
            onFocus={handleFocus}
            onBlur={handleFocus}
            style={{
              textAlign: windowSize.width > breakpointTypes.mobile ? "left" : "center",
            }}
            ref={messageInputRef}
          />
        </div>
      </div>
      <div className={s.confirmation} onClick={handleConf}>
        <div className={s.check}>
          <div
            className={s.dot}
            style={{
              transition: "0.2s all ease",
              opacity: mailConf ? "1" : "0",
            }}
          ></div>
        </div>
        <p className={s.text}>
          Kişisel verilerimin işlenmesine ilişkin{" "}
          <a href={kvkk} target="_blank" rel="noreferrer">
            <u>aydınlatma metnini</u>
          </a>{" "}
          okudum.
        </p>
      </div>
      <div
        className={s.btnWrapper}
        onClick={handleSubmit}
        style={{
          transition: "0.2s all ease",
          opacity: mailConf ? "1" : "0.2",
          pointerEvents: mailConf ? "auto" : "none",
        }}
      >
        {/* <AnimatedButton text="Formu gönder" color="#1a1818" /> */}
        <AnimatedButton sm={contactPage ? true : false} text="Formu Gönder"></AnimatedButton>
      </div>
    </form>
  )
}
