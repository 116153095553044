import React, { useEffect, useRef, useState } from "react";
import s from "../assets/scss/header.module.scss";

import cx from "classnames";
import { Link, useLocation } from "react-router-dom";
import gsap from "gsap";
import { motion, AnimatePresence } from "framer-motion";

import ateonLogo from "../assets/img/ateon-logo.svg";
import ateonLogoWhite from "../assets/img/ateon-logo-white.svg";

import AnimatedButton from "./AnimatedButton";
import { appRoutes } from "../utils/routes";
import IconArrowXs from "./Icon/IconArrowXs";
import IconArrow from "./Icon/IconArrow";
import useWindowSize from "../hooks/useWindowSize";
import { breakpointTypes } from "../utils/types";
import AnimatedLogo from "./AnimatedLogo";

export default function Header() {
  const headerRef = useRef(null);
  const hamburgerTopLine = useRef(null);
  const hamburgerBottomLine = useRef(null);
  const hamburgerBothLinesRef = useRef(null);

  const windowSize = useWindowSize();
  const location = useLocation();

  const [currentMenu, setCurrentMenu] = useState(null);
  const [currentSubmenuItem, setCurrentSubmenuItem] = useState(null);
  const [currentSubmenu, setCurrentSubmenu] = useState(null);
  const [hamburgerOpen, setHamburgerOpen] = useState(false);
  const [logoWhite, setLogoWhite] = useState(false);

  const handleMenu = (type) => {
    // console.log(type, currentMenu);
    if (type === currentMenu) {
      return setCurrentMenu(null);
    }

    setCurrentMenu(type);
  };

  const handleSubmenu = (type) => {
    if (!appRoutes[currentMenu].children[type].children) {
      return;
    }

    if (type === currentSubmenu) {
      return setCurrentSubmenu(null);
    }

    setCurrentSubmenu(type);
  };

  const handleMobileMenu = () => {
    setHamburgerOpen(!hamburgerOpen);
  };

  // Animation Config
  const desktopMenu = {
    initial: { opacity: 0, y: "20%", pointerEvents: "none" },
    animate: { opacity: 1, y: "0", pointerEvents: "auto" },
    exit: { opacity: 0, y: "20%", pointerEvents: "none" },
    transition: {
      duration: 0.3,
      ease: "easeInOut",
    },
  };

  const touchscreenMenu = {
    initial: { opacity: 0, maxHeight: 0, x: -20, pointerEvents: "none" },
    animate: { opacity: 1, maxHeight: "100vh", x: 0, pointerEvents: "auto" },
    exit: { opacity: 0, maxHeight: 0, x: -20, pointerEvents: "none" },
    transition: {
      duration: 1.2,
      ease: "easeInOut",
    },
  };

  const touchscreenSubmenuItemsAnim = {
    initial: { opacity: 0, maxHeight: 0, pointerEvents: "none" },
    animate: { opacity: 1, maxHeight: "100vh", pointerEvents: "auto" },
    exit: { opacity: 0, maxHeight: 0, pointerEvents: "none" },
    transition: {
      duration: 1,
      ease: "easeInOut",
    },
  };

  const animConfig =
    windowSize.width > breakpointTypes.tablet ? desktopMenu : touchscreenMenu;

  // Hamburger X animation
  useEffect(() => {
    if (hamburgerOpen && windowSize.width > breakpointTypes.mobile) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "auto";
    }

    if (hamburgerOpen) {
      hamburgerBothLinesRef.current &&
        gsap.to(hamburgerBothLinesRef.current, {
          transformOrigin: "50% 50%",
          scale: 1,
          onStart: () => {
            setLogoWhite(true);
          },
        });

      hamburgerTopLine.current &&
        gsap.to(hamburgerTopLine.current, {
          transformOrigin: "50% 50%",
          rotate: 45,
          yPercent: 200,
          onStart: () => {
            setLogoWhite(true);
          },
        });

      hamburgerBottomLine.current &&
        gsap.to(hamburgerBottomLine.current, {
          transformOrigin: "50% 50%",
          rotate: -45,
          yPercent: -200,
        });
    } else {
      hamburgerBothLinesRef.current &&
        gsap.to(hamburgerBothLinesRef.current, {
          transformOrigin: "50% 50%",
          scale: 0.7,
          onStart: () => {
            setLogoWhite(true);
          },
        });

      hamburgerTopLine.current &&
        gsap.to(hamburgerTopLine.current, {
          transformOrigin: "50% 50%",
          rotate: 0,
          yPercent: 0,
        });

      hamburgerBottomLine.current &&
        gsap.to(hamburgerBottomLine.current, {
          transformOrigin: "50% 50%",
          rotate: 0,
          yPercent: 0,
          onStart: () => {
            setLogoWhite(false);
          },
        });
    }
  }, [hamburgerOpen]);

  useEffect(() => {
    setCurrentMenu(null);
    setCurrentSubmenu(null);
    setCurrentSubmenuItem(null);
    setHamburgerOpen(false);
  }, [location]);

  useEffect(() => {
    setCurrentSubmenu(null);
    setCurrentSubmenuItem(null);
  }, [currentMenu]);

  useEffect(() => {
    setCurrentSubmenuItem(null);
  }, [currentSubmenu]);

  return (
    <header className={s.header} ref={headerRef}>
      <Link to="/">
        <div className={s.logoWrapper}>
          {windowSize.width <= breakpointTypes.tablet ? (
            <>
              <img
                style={{ opacity: logoWhite ? "1" : "0" }}
                className={s.logo}
                src={ateonLogoWhite}
                alt="Ateon Logo"
              />

              <img
                style={{ opacity: logoWhite ? "0" : "1" }}
                className={s.logo}
                src={ateonLogo}
                alt="Ateon Logo"
              />
            </>
          ) : (
            <AnimatedLogo></AnimatedLogo>
          )}
        </div>
      </Link>

      <nav className={cx(s.navigation, { [s.open]: hamburgerOpen })}>
        {Object.entries(appRoutes).map(([key, value], i) => {
          return !value.children ? (
            <Link className={s.navItem} to={`/${value.path}`} key={i}>
              <div className={s.itemWrapper}>
                <p className={s.item}>{value.ui}</p>
              </div>
            </Link>
          ) : (
            <div
              className={cx(s.navItem, s.border)}
              key={i}
              onMouseEnter={() =>
                windowSize.width > breakpointTypes.tablet &&
                handleMenu(value.type)
              }
              onMouseLeave={() =>
                windowSize.width > breakpointTypes.tablet && handleMenu(null)
              }
            >
              <div
                className={s.itemWrapper}
                onClick={() =>
                  windowSize.width <= breakpointTypes.tablet &&
                  handleMenu(value.type)
                }
              >
                <p className={s.item}>{value.ui}</p>
                <div className={s.arrowWrapper}>
                  <div
                    className={cx(s.arrow, {
                      [s.rotate]: currentMenu === value.type,
                    })}
                  >
                    {windowSize.width > breakpointTypes.tablet ? (
                      <IconArrowXs />
                    ) : (
                      <IconArrow fill="#ffffff" />
                    )}
                  </div>
                </div>
              </div>
              <div className={s.menuWrapper}>
                <AnimatePresence>
                  {currentMenu === value.type && (
                    <motion.div
                      className={cx(s.menu, {
                        [s.subOpen]:
                          currentSubmenu &&
                          currentMenu !== appRoutes.services.type,
                        [s.veritas]: currentMenu === appRoutes.veritas.type,
                        [s.solutions]: currentMenu === appRoutes.solutions.type,
                        [s.services]: currentMenu === appRoutes.services.type,
                      })}
                      key={currentMenu}
                      {...animConfig}
                    >
                      <ul className={s.menuItems}>
                        {Object.entries(appRoutes[currentMenu].children).map(
                          ([key, value], i) => {
                            const parent = value;
                            return (
                              <li
                                className={cx(s.menuItem, {
                                  [s.fade]:
                                    currentSubmenu &&
                                    currentSubmenu !== value.type &&
                                    appRoutes[currentMenu].type !==
                                      appRoutes.services.type,
                                })}
                                key={i}
                                onClick={() => handleSubmenu(value.type)}
                              >
                                {currentMenu === appRoutes.services.type ? (
                                  <Link
                                    className={s.link}
                                    to={value.parentPath + "/" + value.path}
                                  >
                                    <div className={s.arrowMenu}>
                                      <IconArrowXs />
                                    </div>
                                    <span className={s.text}>{value.ui}</span>
                                  </Link>
                                ) : (
                                  <div
                                    className={cx(s.link, {
                                      [s.activeMobile]:
                                        currentSubmenu === value.type,
                                    })}
                                  >
                                    <div className={s.arrowMenu}>
                                      <IconArrowXs />
                                    </div>
                                    <span className={s.text}>{value.ui}</span>
                                  </div>
                                )}
                                <AnimatePresence>
                                  {currentSubmenu === value.type && (
                                    <motion.div
                                      className={s.submenu}
                                      key={currentSubmenu}
                                      {...touchscreenSubmenuItemsAnim}
                                    >
                                      <ul className={s.submenuItems}>
                                        <AnimatePresence>
                                          {Object.entries(
                                            appRoutes[currentMenu].children[key]
                                              .children
                                          ).map(([key, value], i) => {
                                            return (
                                              <motion.li
                                                className={cx(s.submenuItem, {
                                                  [s.fade]:
                                                    currentSubmenuItem &&
                                                    currentSubmenuItem !==
                                                      value.type,
                                                })}
                                                key={i}
                                                onMouseEnter={() => {
                                                  setCurrentSubmenuItem(
                                                    value.type
                                                  );
                                                }}
                                              >
                                                <Link
                                                  className={s.linkSubmenu}
                                                  to={`${parent.path}/${value.path}`}
                                                >
                                                  <span
                                                    className={s.textSubmenu}
                                                  >
                                                    {value.ui}
                                                  </span>
                                                </Link>
                                              </motion.li>
                                            );
                                          })}
                                        </AnimatePresence>
                                      </ul>
                                    </motion.div>
                                  )}
                                </AnimatePresence>
                              </li>
                            );
                          }
                        )}
                      </ul>
                    </motion.div>
                  )}
                </AnimatePresence>
              </div>
            </div>
          );
        })}
      </nav>

      {windowSize.width > breakpointTypes.tablet ? (
        <a
          href="https://destek.ateon.com.tr"
          target="_blank"
          rel="noreferrer"
          className={s.btnWrapper}
        >
          <AnimatedButton sm text="Destek Al"></AnimatedButton>
        </a>
      ) : (
        <div className={s.btnWrapper} onClick={handleMobileMenu}>
          <div className={s.bgWrapper}>
            <svg
              className={cx(s.bg, { [s.open]: hamburgerOpen })}
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 110 110"
            >
              <g
                id="Group_1788"
                data-name="Group 1788"
                transform="translate(-589 -52)"
              >
                <circle
                  id="Ellipse_28"
                  data-name="Ellipse 28"
                  cx="55"
                  cy="55"
                  r="55"
                  transform="translate(589 52)"
                  fill="#002855"
                />
              </g>
            </svg>
          </div>
          <div className={s.hamburger}>
            <svg
              className={s.icon}
              xmlns="http://www.w3.org/2000/svg"
              width="15"
              height="5"
              viewBox="0 0 15 5"
              ref={hamburgerBothLinesRef}
            >
              <g
                id="Group_975"
                data-name="Group 975"
                transform="translate(-325 -50)"
              >
                <rect
                  id="Rectangle_2479"
                  data-name="Rectangle 2479"
                  width="15"
                  height="1"
                  rx="0.5"
                  transform="translate(325 50)"
                  fill="#f5f5f5"
                  ref={hamburgerTopLine}
                />
                <rect
                  id="Rectangle_2481"
                  data-name="Rectangle 2481"
                  width="15"
                  height="1"
                  rx="0.5"
                  transform="translate(325 54)"
                  fill="#f5f5f5"
                  ref={hamburgerBottomLine}
                />
              </g>
            </svg>
          </div>
        </div>
      )}
    </header>
  );
}
